import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Img from 'gatsby-image'

const QUERY = graphql`
  query {
    categories: allContentfulCategory {
      edges {
        node {
          category
          slug
          image {
            fluid(maxWidth: 480, maxHeight: 320) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            description
          }
        }
      }
    }
    images: allFile(filter: {relativePath: {eq: "sinuca.jpg"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 480, maxHeight: 320) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const Produtos = () => {
  const { categories, images } = useStaticQuery(QUERY)

  return (
    <Layout>
      <SEO />
      <h1 className='invisible'>Produtos</h1>
      <div className='w-11/12 mx-auto mt-2 md:w-4/5'>
        <p className='flex robika w-full sm:w-4/5 lg:w-2/3 uppercase underlin text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl md:mt-10 mb-4 font-bold'>Produtos</p>
        <p className='font-thin text-sm md:text-xs lg:text-base xl:text-lg'>Escolha a linha de produtos</p>

        <section className='mx-auto vitrine'>
          <div className='flex items-center flex-col pt-4 md:pt-6 pb-12'>
            {
              categories.edges.map(category => {
                if (category.node.slug !== 'sem-categoria') {
                  return (
                    <Link key={category.node.slug} className='shadow handle-text-color md:hover:shadow-2xl md:hover:grow md:hover:bg-gray-400 w-full md:w-2/3 flex flex-row bg-gray-600 my-1 md:my-2' to={'/'+category.node.slug}>
                      <Img className='w-1/2 bg-white' fluid={category.node.image.fluid} />
                      <div className='w-1/2 pt-3 flex flex-col m-2'>
                        <p className='pl-2 text-sm sm:text-lg lg:text-xl font-bold text-white text-color pb-2 underline'>{category.node.category}</p>
                        <p className='pl-2 text-xs xl:text-sm text-white text-color'>{category.node.description.description}</p>
                      </div>
                    </Link>
                  )
                }
              })
            }
            <Link key='acessorios' className='handle-text-color md:hover:shadow-2xl md:hover:grow md:hover:bg-gray-400 w-full md:w-2/3 flex flex-row bg-gray-600 my-1 md:my-2' to='/acessorios'>
              <Img className='w-1/2 bg-white' fluid={images.edges[0].node.childImageSharp.fluid} />
              <div className='w-1/2 pt-3 flex flex-col m-2'>
                <p className='pl-2 text-sm sm:text-lg lg:text-xl font-bold text-white text-color pb-2 underline'>Acessórios</p>
                <p className='pl-2 text-xs xl:text-sm text-white text-color'>Linha de acessórios exclusiva.</p>
              </div>
            </Link>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Produtos
